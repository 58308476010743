body {
  font-family: Roboto, sans-serif;
  background-color: bisque;
}
body .container {
  padding: 0;
}

smal {
  font-size: 12px;
  display: block;
  margin: 5px;
  font-weight: 500;
  text-decoration: underline;
}

.updateServerBottom {
  position: fixed;
  bottom: 0;
  height: 40px;
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: start;
  align-items: center;
}
.updateServerBottom .updateServer {
  color: white;
  line-height: 1;
  padding-bottom: 5px;
}
.updateServerBottom .updateServer img {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.top-m25 {
  margin-top: -25px;
}

.hrBlock {
  width: 100%;
  position: absolute;
  bottom: 15px;
}

.hrBlockStart {
  width: 100%;
  position: absolute;
  bottom: 15px;
}

.boxed .auchTextError {
  color: #ffffff;
  border-radius: 5px;
  border-top: 2px #7589a9 solid !important;
}

.updateScreenBlock {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  opacity: 0.6;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
}
.updateScreenBlock img {
  width: 100px;
  height: auto;
  display: block;
  margin: auto;
}

.btnSaveWrapper {
  width: 100%;
  min-height: 80px;
  height: auto;
  padding: 0;
  background-color: #1E74FD;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 200;
  border-top: 2px #ffffff solid;
}
.btnSaveWrapper .btnSave {
  border: 2px #fff solid;
  border-radius: 15px;
  padding: 15px 5px;
  color: white;
  background: #0b49d0;
  background: linear-gradient(0deg, #0b49d0 0, #0b49d0 23%, #00d4ff 100%);
}

.success_notify {
  bottom: 0 !important;
  height: 50px !important;
  background-color: green !important;
  padding: 15px 5px !important;
  border-top: 2px white solid !important;
  opacity: 0;
  animation: ani 2.5s forwards;
}

.success_notify .notifySuccess {
  color: white;
}

.error_notify {
  bottom: 0 !important;
  padding: 15px 10px !important;
  background-color: red !important;
  border-top: 2px white solid !important;
  opacity: 0;
  animation: ani 2.5s forwards;
}

.error_notify .notifyError {
  color: white;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.warehouse_icon {
  width: 40px;
  height: 100%;
  min-height: 50px;
  margin-right: 15px;
  min-width: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.warehouse_desc {
  display: flex;
  align-items: center;
}

.flexBlock {
  display: flex;
  flex-direction: row;
}

.bottom_space_list {
  width: 100%;
  height: 50px;
  display: block;
}

.testConnectRes {
  font-size: 22px;
  color: black;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 30px;
  border: 1px #000 dashed;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}

.downIco {
  width: 50px;
  height: 50px;
  display: block;
  margin: 15px auto;
  position: relative;
}

.downText {
  color: #ffffff;
  text-align: center;
  display: block;
  padding: 0 15px;
}

.screenInfoBlock {
  width: 100%;
  height: 90vh;
  padding-top: 100px;
  position: relative;
  text-align: center;
}
.screenInfoBlock .exitBtn {
  border-radius: 5px;
}

.sendBtn {
  display: block;
  margin: 15px auto;
  width: 300px !important;
  text-align: center;
  border: 2px #fff solid !important;
  color: #fff;
  padding-top: 0px !important;
  cursor: pointer;
}

.sendBtn:active {
  border: 2px #1E74FD solid !important;
  color: #1E74FD !important;
  background-color: #fff !important;
}

.editInput {
  width: 100%;
  border-radius: 3px;
  font-size: 10px;
}

input[type=number].editInput {
  width: 100%;
  display: block;
  background-color: #f5eaa7;
  margin: 0 auto;
  padding: 0;
  height: 40px;
  border: none;
  max-width: 70px;
  text-align: center;
}

.editBox {
  width: 50px;
  margin: 0;
  padding: 0;
  position: relative;
  right: 0px;
  text-align: center;
}

.editBox img {
  width: 30px;
  position: relative;
  top: 10px;
  right: 0;
  cursor: pointer;
}

.editTitleActive {
  text-align: center;
  display: block;
  padding: 10px 15px 15px 15px;
  margin-top: -15px;
  color: #e4e7e3;
}

.itemBlockStage {
  display: block;
  padding-top: 10px;
  padding-left: 5px;
  min-height: 35px;
  height: auto;
  background-color: white;
  width: calc(100% - 10px);
  color: #141515 !important;
  border: 1px #ccc solid;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
}
.itemBlockStage h3 {
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  display: block;
  padding-bottom: 5px;
  position: relative;
}
.itemBlockStage h3 img {
  position: absolute;
  top: -5px;
  right: 5px;
}
.itemBlockStage .akkardeon {
  display: block;
  height: 0;
  overflow: hidden;
}
.itemBlockStage .akkardeon .pointStage {
  background-color: #0564fd;
  color: white;
  padding: 15px;
  width: calc(100% - 5px);
  border-radius: 5px;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.itemBlockStage .akkardeon .pointStage .name {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}
.itemBlockStage .akkardeon .pointStage .quantity {
  background-color: white;
  border: 2px rgba(20, 21, 21, 0.9) solid;
  color: #141515;
  min-width: 20%;
  max-width: 20%;
  width: 20%;
  text-align: center;
  min-height: 30px;
  height: 30px;
  display: flex;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.itemBlockStage .akkardeon .pointStage .quantity input[type=number] {
  width: 100%;
  display: block;
  background-color: #f5eaa7;
  margin: 0 auto;
  padding: 0;
  height: 40px;
  border: none;
  max-width: 70px;
  text-align: center;
}
.itemBlockStage .akkardeon .pointStage .saveIcon {
  width: 50px;
  height: 50px;
  display: block;
  margin: 5px;
  text-align: center;
}
.itemBlockStage .akkardeon .pointStage .saveIcon img {
  width: 30px;
  padding: 0;
  margin: 0;
  position: relative;
  top: -5px;
}
.itemBlockStage .akkardeon .selectBlock {
  background-color: #39be39 !important;
  color: #141515;
}
.itemBlockStage .akkardeon .editPointBG {
  background-color: #f3f3f3 !important;
  border-style: dashed;
  box-shadow: inset -4px -4px 16px 0px rgba(0, 0, 0, 0.1);
  color: #141515;
}
.itemBlockStage .akkardeon .editPointBG .quantity {
  border-style: dashed !important;
}
.itemBlockStage .akkardeon.open {
  height: auto;
}

.itemBlockObject {
  display: block;
  padding-top: 10px;
  padding-left: 5px;
  min-height: 55px;
  height: auto;
  background-color: white;
  width: calc(100% - 10px);
  color: #141515 !important;
  border: 1px #ccc solid;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
}
.itemBlockObject p {
  line-height: 1;
  font-size: clamp(0.9375rem, 0.7247rem + 1.0638vw, 1.25rem);
  font-weight: 600;
}
.itemBlockObject p span {
  font-size: 30px;
  line-height: 1;
  display: block;
  float: left;
  margin-right: 5px;
  color: green;
}

.itemBlockDocument {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  min-height: 60px;
  height: auto;
  background-color: white;
  width: calc(100% - 10px);
  color: #141515 !important;
  border: 1px #ccc solid;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
}
.itemBlockDocument div:first-child {
  line-height: 1;
  font-size: clamp(0.9375rem, 0.7247rem + 1.0638vw, 1.25rem);
  width: 70%;
  align-self: center;
}
.itemBlockDocument div:first-child .titleDocument {
  width: 100%;
  text-align: left;
  justify-content: left;
  line-height: 1.2;
  padding-right: 5px;
  font-size: clamp(0.9375rem, 0.7247rem + 1.0638vw, 1.25rem);
}
.itemBlockDocument div:first-child .titleDocument .hydrated {
  font-size: clamp(0.9375rem, 0.7247rem + 1.0638vw, 1.25rem);
  margin: 0 5px 0 0 !important;
  color: red !important;
  width: 21px;
  max-width: 21px;
  min-width: 21px;
  height: 21px;
  display: block;
  float: left;
}
.itemBlockDocument div:last-child {
  display: flex;
  justify-content: space-around;
  width: 30%;
  text-align: center;
  font-weight: bold;
  margin: 5px;
  font-size: clamp(0.9375rem, 0.7247rem + 1.0638vw, 1.25rem);
  align-items: center;
}
.itemBlockDocument div:last-child div {
  width: 50%;
  border: none;
  align-items: center;
}

.itemBlockLoacation {
  display: flex;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
  min-height: 60px;
  height: auto;
  background-color: #fff;
  width: calc(100% - 10px);
  color: #141515 !important;
  border: 1px #ccc solid;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  align-content: center;
  align-items: center;
}
.itemBlockLoacation p {
  font-size: clamp(0.9375rem, 0.7247rem + 1.0638vw, 1.25rem);
}
.itemBlockLoacation p span {
  font-size: 18px;
  line-height: 1;
  display: block;
  float: left;
  margin-right: 10px;
  color: green;
}

.container {
  overflow-y: auto;
  height: 100vh;
}

.refresh-overlay {
  position: fixed;
  top: -50px;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: top 0.3s ease;
}

.refresh-overlay.show {
  top: 0;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.screenBlock {
  width: 100%;
  min-height: 100vh;
  background-color: #1E74FD;
  padding-top: 100px;
  padding-bottom: 2px;
  position: relative;
}
.screenBlock .NominalSet {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: white;
  padding: 15px 0;
}
.screenBlock .editItem {
  background-color: bisque;
}
.screenBlock .acceptedItem {
  background-color: #a1a1a2 !important;
}
.screenBlock .supplyQuantity {
  background-color: #e33131 !important;
}
.screenBlock .allMaterialSupplyClass {
  background-color: #e33131 !important;
  color: white !important;
}
.screenBlock .allMaterialSupplyClass span {
  color: white !important;
}
.screenBlock .planBlockObject {
  background-color: #0757a8 !important;
  color: #e4e3e3 !important;
}
.screenBlock .planBlockObject span {
  color: #e4e3e3 !important;
}
.screenBlock .archiveBlockObject {
  background-color: #a1a1a2 !important;
  color: white !important;
}
.screenBlock .archiveBlockObject span {
  color: white !important;
}
.screenBlock .planItem {
  background-color: #a1a1a2 !important;
  color: white !important;
}
.screenBlock .archivItem {
  background-color: #a1a1a1 !important;
  color: white !important;
}

.section {
  padding: 0 16px;
}

.section.full {
  padding: 0;
}

#appCapsule {
  padding: 56px 0;
  margin-bottom: env(safe-area-inset-bottom);
  margin-top: env(safe-area-inset-top);
}

#appCapsule.extra-header-active {
  padding-top: 112px;
}

#appCapsule.full-height {
  min-height: 100vh;
}

.login-form {
  max-width: 500px;
  margin: auto;
  text-align: center;
}

.login-form .form-image {
  margin: 30px 0;
  width: 100%;
  max-width: 150px !important;
  height: auto;
}

.title_form_block {
  margin-bottom: 30px;
}

textarea,
.form-control {
  background-clip: padding-box;
  background-image: linear-gradient(transparent, transparent);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-button-group {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  background: #FFF;
  min-height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: env(safe-area-inset-bottom);
}

.btn-primary {
  background: #1E74FD !important;
  border-color: #1E74FD !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: calc(100% - 30px);
  margin: 0 auto;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  background: #0564fd !important;
  border-color: #0564fd !important;
}

.btn-primary.disabled, .btn-primary:disabled {
  background: #1E74FD;
  border-color: #1E74FD;
  opacity: 0.5;
}

.btn-lg {
  min-height: 48px;
  padding: 0;
  height: auto;
  font-size: 1.2rem;
}

.btn-lg-w {
  min-height: 48px;
  padding: 0;
  height: auto;
  display: flex;
  line-height: 1.2;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.btn-lg i.bi,
.btn-lg i.icon,
.btn-lg ion-icon {
  font-size: 26px;
}

.btn-block {
  width: 100%;
  border-radius: 5px;
}

.form-group {
  width: 100%;
}

.form-group .form-label {
  font-size: 11px;
  font-weight: 500;
  color: #141515;
  display: block;
  line-height: 1.2em;
  text-align: left;
}

.form-group textarea {
  resize: none;
}

.form-group .input-info {
  font-size: 11px;
  color: #A1A1A2;
}

.form-group .clear-input {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4F5050;
  height: 38px;
  font-size: 22px;
  position: absolute;
  z-index: 2;
  right: -10px;
  bottom: 0;
  width: 32px;
  opacity: 0.5;
  display: none;
}

.form-group .clear-input i.bi {
  font-size: 20px;
}

.form-group .clear-input:hover, .form-group .clear-input:active {
  opacity: 0.8;
}

.form-group .input-wrapper {
  position: relative;
}

.form-group .input-wrapper.not-empty .clear-input {
  display: flex;
}

.form-group .input-wrapper.active .form-label {
  color: #1E74FD !important;
}

.form-group.basic {
  padding: 8px 0;
  margin: 0;
}

.form-group.basic .form-label {
  margin-bottom: 0;
}

.form-group.basic .form-control,
.form-group.basic .custom-select {
  background: transparent;
  border: none;
  border-bottom: 1px solid #E1E1E1;
  padding: 0 30px 0 0;
  border-radius: 0;
  height: 40px;
  color: #141515;
  font-size: 15px;
}

.form-group.basic .form-control:focus,
.form-group.basic .custom-select:focus {
  border-bottom-color: #1E74FD;
  box-shadow: inset 0 -1px 0 0 #1E74FD;
}

.form-group.basic textarea.form-control {
  height: auto;
  padding: 7px 40px 7px 0;
}

.form-group.basic.animated .form-label {
  margin-top: 20px;
  opacity: 0;
  top: -3px;
  transition: 0.2s all;
  position: absolute;
}

.form-group.basic.animated .input-wrapper {
  padding-top: 5px;
}

.form-group.basic.animated .input-wrapper.not-empty .form-label {
  margin-top: 0;
  opacity: 1;
}

.form-group.boxed {
  margin: 0;
  padding: 8px 0;
}

.form-group.boxed .form-control {
  background: #fff;
  box-shadow: none;
  height: 42px;
  border-radius: 6px;
  padding: 0 40px 0 16px;
  width: 100%;
  border-color: #E1E1E1;
}

.form-group.boxed .form-control:focus {
  border-color: #1E74FD;
}

.form-group.boxed textarea.form-control {
  height: auto;
  padding: 7px 40px 7px 16px;
}

.form-group.boxed .clear-input {
  right: 0;
  height: 42px;
  width: 40px;
}

.form-group.boxed .form-label {
  margin-bottom: 8px;
}

nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 0;
  background-color: #6099fc;
}
nav .active, nav .is_active {
  background-color: #0b49d0;
  color: white;
  height: auto;
  margin-top: -5px;
}
nav a {
  width: 25%;
  display: block;
  border: 1px #ccc solid;
  padding: 10px 5px 5px 5px;
  color: #0b49d0;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
nav a img {
  width: 30px;
}

.header {
  height: 50px;
  background-color: #0b49d0;
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: flex-start;
  z-index: 11;
}
.header .filterIcon {
  width: 40px;
  height: 40px;
  text-align: center;
  color: white;
  padding-top: 10px;
  padding-left: 10px;
  cursor: pointer;
}
.header .titleHeader {
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  width: calc(100% - 100px);
  overflow-y: hidden;
  position: relative;
}
.header .titleHeader p {
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  overflow: scroll;
  width: max-content;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding-left: 10px;
}

.screenBtnBlock {
  display: flex;
  margin: 15px auto;
  width: 100%;
  flex-direction: column;
}
.screenBtnBlock a {
  background-color: #fff;
  border: 2px solid #141515;
  border-radius: 5px;
  color: #141515;
  min-height: 50px;
  height: auto;
  margin: 5px auto;
  text-decoration: none;
  width: 60%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.pageErrorMargin {
  margin-top: 25px;
}

.filter {
  display: flex;
  position: fixed;
  width: 100%;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.84);
  z-index: 10;
  padding-top: 50px;
}
.filter .close {
  position: fixed;
  left: calc(50% - 15px);
  bottom: 80px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}
.filter .filterTitle {
  padding-top: 50px;
  text-align: center;
  color: white;
  display: block;
  width: 100%;
}
.filter .input_line {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  color: white;
}
.filter .input_line label {
  text-align: left;
  margin-top: 10px;
  font-size: 10px;
  position: relative;
  top: 5px;
  left: 10px;
  color: #d5d4d4;
}
.filter .input_line select {
  color: #dadada;
  background-color: rgba(6, 53, 154, 0.6);
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0 10px;
}
.filter .input_line select option {
  width: 100%;
}
.filter .position_2 {
  top: 105px;
}
.filter .position_3 {
  top: 170px;
}

.footerBlock {
  height: 100px;
  width: 100%;
  background-color: bisque;
  color: #000;
  text-align: center;
  padding-top: 15px;
}

.sendAnimate {
  position: fixed;
  top: 42px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.hr_line {
  width: 100%;
  height: 75px;
  float: left;
  clear: both;
}

.usageBlockWrapper {
  width: 100%;
  position: absolute;
  top: 58px;
  right: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
  align-content: flex-start;
  align-items: center;
}
.usageBlockWrapper .tg-list {
  text-align: center;
  display: flex;
  align-items: center;
}
.usageBlockWrapper .tg-list-item {
  margin: 0 2em;
}
.usageBlockWrapper h4 {
  color: #eae8e8;
  display: block;
  margin-right: 15px;
}
.usageBlockWrapper .tgl {
  display: none;
}
.usageBlockWrapper .tgl, .usageBlockWrapper .tgl:after, .usageBlockWrapper .tgl:before, .usageBlockWrapper .tgl *, .usageBlockWrapper .tgl *:after, .usageBlockWrapper .tgl *:before, .usageBlockWrapper .tgl + .tgl-btn {
  box-sizing: border-box;
}
.usageBlockWrapper .tgl::selection, .usageBlockWrapper .tgl:after::selection, .usageBlockWrapper .tgl:before::selection, .usageBlockWrapper .tgl *::selection, .usageBlockWrapper .tgl *:after::selection, .usageBlockWrapper .tgl *:before::selection, .usageBlockWrapper .tgl + .tgl-btn::selection {
  background: none;
}
.usageBlockWrapper .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.usageBlockWrapper .tgl + .tgl-btn:after, .usageBlockWrapper .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.usageBlockWrapper .tgl + .tgl-btn:after {
  left: 0;
}
.usageBlockWrapper .tgl + .tgl-btn:before {
  display: none;
}
.usageBlockWrapper .tgl:checked + .tgl-btn:after {
  left: 50%;
}
.usageBlockWrapper .tgl-light + .tgl-btn {
  background: #f0f0f0;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.usageBlockWrapper .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}
.usageBlockWrapper .tgl-light:checked + .tgl-btn {
  background: #9FD6AE;
}
.usageBlockWrapper .tgl-ios + .tgl-btn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}
.usageBlockWrapper .tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.usageBlockWrapper .tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.usageBlockWrapper .tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.usageBlockWrapper .tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}
.usageBlockWrapper .tgl-ios:checked + .tgl-btn {
  background: #86d993;
}
.usageBlockWrapper .tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.usageBlockWrapper .tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}
.usageBlockWrapper .tgl-skewed + .tgl-btn {
  overflow: hidden;
  transform: skew(-10deg);
  backface-visibility: hidden;
  transition: all 0.2s ease;
  font-family: sans-serif;
  background: #888;
}
.usageBlockWrapper .tgl-skewed + .tgl-btn:after, .usageBlockWrapper .tgl-skewed + .tgl-btn:before {
  transform: skew(10deg);
  display: inline-block;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.usageBlockWrapper .tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}
.usageBlockWrapper .tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}
.usageBlockWrapper .tgl-skewed + .tgl-btn:active {
  background: #888;
}
.usageBlockWrapper .tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}
.usageBlockWrapper .tgl-skewed:checked + .tgl-btn {
  background: #86d993;
}
.usageBlockWrapper .tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}
.usageBlockWrapper .tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}
.usageBlockWrapper .tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}
.usageBlockWrapper .tgl-flat + .tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  background: #fff;
  border: 4px solid #f2f2f2;
  border-radius: 2em;
}
.usageBlockWrapper .tgl-flat + .tgl-btn:after {
  transition: all 0.2s ease;
  background: #f2f2f2;
  content: "";
  border-radius: 1em;
}
.usageBlockWrapper .tgl-flat:checked + .tgl-btn {
  border: 4px solid #7FC6A6;
}
.usageBlockWrapper .tgl-flat:checked + .tgl-btn:after {
  left: 50%;
  background: #7FC6A6;
}
.usageBlockWrapper .tgl-flip + .tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  font-family: sans-serif;
  perspective: 100px;
}
.usageBlockWrapper .tgl-flip + .tgl-btn:after, .usageBlockWrapper .tgl-flip + .tgl-btn:before {
  display: inline-block;
  transition: all 0.4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 4px;
}
.usageBlockWrapper .tgl-flip + .tgl-btn:after {
  content: attr(data-tg-on);
  background: #02C66F;
  transform: rotateY(-180deg);
}
.usageBlockWrapper .tgl-flip + .tgl-btn:before {
  background: #FF3A19;
  content: attr(data-tg-off);
}
.usageBlockWrapper .tgl-flip + .tgl-btn:active:before {
  transform: rotateY(-20deg);
}
.usageBlockWrapper .tgl-flip:checked + .tgl-btn:before {
  transform: rotateY(180deg);
}
.usageBlockWrapper .tgl-flip:checked + .tgl-btn:after {
  transform: rotateY(0);
  left: 0;
  background: #7FC6A6;
}
.usageBlockWrapper .tgl-flip:checked + .tgl-btn:active:after {
  transform: rotateY(20deg);
}

